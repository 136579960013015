import { toHex } from '@smithy/util-hex-encoding'
import { Sha256 } from '@aws-crypto/sha256-browser'

function computeHash (text) {
  const hash = new Sha256()
  hash.update(text)

  return hash.digest().then(function (result) {
    return toHex(result)
  })
}

window.computeHash = computeHash
